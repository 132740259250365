<template>
  <div>
    <ag-grid-vue
      :key="agGridKey"
      ref="agGridTable"
      :domLayout="domLayout"
      :style="gridStyle"
      :components="components"
      :frameworkComponents="frameworkComponents"
      :gridOptions="gridOptions"
      class="ag-theme-material w-100 ag-grid-table"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :column-types="columnTypes"
      :getRowNodeId="getRowNodeId"
      :autoParamsRefresh="true"
      rowSelection="multiple"
      :animateRows="true"
      :suppressRowClickSelection="true"
      :enableRtl="$vs.rtl"
      rowModelType="infinite"
      :pagination="hasPagination"
      :paginationPageSize="paginationPageSize"
      :suppressPaginationPanel="suppressPaginationPanel"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
      :cacheBlockSize="cacheBlockSize"
      :maxBlocksInCache="maxBlocksInCache"
      @selection-changed="onSelectionChanged"
      @grid-ready="onGridReady"
      @model-updated="onModelUpdate"
      @filter-changed="onFilterChanged"
      @pagination-changed="onPaginationChanged">
    </ag-grid-vue>
  </div>
</template>

<script>

// Mixins
import commonAgGrid from '@/views/modules/_mixins/commonAgGrid';

// Custom Components
import CellRendererStatus from '@/views/modules/campaigns/components/campaign-specified-chart/CampaignSpecifiedChartDetailsTableCellRendererStatus.vue';
import CellRendererLargeText from '@/views/modules/_components/cell-renderer/CellRendererLargeTextModal.vue';

export default {
  name: 'CampaignSpecifiedChartDetailsTable',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CellRendererStatus,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererLargeText,
  },
  mixins: [commonAgGrid],
  props: {
    fetchDetailsFunction: {
      type: Function,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
      defaultPaginationPageSize: 5,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'contact.name',
          filter: false,
          sortable: false,
          minWidth: 100,
          maxWidth: 400,
        },
        {
          coldId: 'phone',
          headerName: this.$tc('$General.Phone'),
          field: 'to',
          filter: false,
          sortable: false,
          minWidth: 100,
          maxWidth: 200,
          valueFormatter: (params) => this.$options.filters.phone(params.value),
        },
        {
          colId: 'message',
          headerName: this.$tc('$General.Message'),
          field: 'message',
          filter: false,
          sortable: false,
          minWidth: 300,
          maxWidth: 800,
          cellRendererFramework: 'CellRendererLargeText',
          cellRendererParams: {
            above: true,
          },
        },
        {
          colId: 'status',
          headerName: this.$t('$General.Status'),
          headerClass: 'center-herder-ag-grid',
          field: 'status',
          filter: false,
          sortable: false,
          minWidth: 100,
          maxWidth: 300,
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          colId: 'createdAt',
          headerName: 'Delivery time',
          field: 'deliveryTime',
          sort: 'desc',
          type: 'dateColumn',
          filter: false,
        },
      ],
      components: {
        CellRendererStatus,
      },
      frameworkComponents: {},
    };
  },
  methods: {
    async fetchAgGridData(params) {
      const resp = await this.fetchDetailsFunction({
        id: this.campaignId,
        params: {
          ...params,
          populate: [{
            path: 'contact',
            select: 'id firstName lastName name',
          }],
        },
      });

      if (resp.count) {
        this.count = resp.count;
      }

      return resp;
    },
  },
};
</script>

<style scoped>

</style>
